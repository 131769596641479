import http from 'utils/http'

const fingerprintEvaluate = async () => {
    if (process.env.REACT_APP_FINGERPRINT_EVALUATE === 'enable') {
        const response = await http.post('/fingerprint/evaluate')
        return response
    }
    return true
}

const update = async (userId, body) => {
    const response = await http.post(`/users/${userId}`, body)
    return response
}

const mlaCheckSurvey = async () => {
    const response = await http.post(`/check_mla_survey`)
    return response
}

const checkEligibilityEvaluateVeritec = async (user_id) => {
    const response = await http.get(`/check-eligibility/${user_id}/evaluate`)
    return response
}

const creditReport = async () => {
    const response = await http.post('/credit-reports')
    return response
}
export default {
    update,
    checkEligibilityEvaluateVeritec,
    creditReport,
    fingerprintEvaluate,
    mlaCheckSurvey
}
