import React, { useEffect, useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import { UserContext } from './../../../context/UserContext'
import { ChevronLeftIcon } from 'assets/icons_v3/fonts'
import ChatSVG from 'assets/onboarding_v3/chat.svg'

const TRENGO_FIELD_ID = process.env.REACT_APP_TRENGO_FIELD_ID

export default function LiveChat() {
    const navigate = useNavigate()
    const widget = document.getElementById('trengo-web-widget')
    const {
        state: { user }
    } = useContext(UserContext)

    useEffect(() => {
        startChatLive()
    }, [])

    const startChatLive = async () => {
        widget.style.display = 'block'
        window.Trengo.Api.Widget.render()

        await new Promise((r) => setTimeout(r, 150))

        window.Trengo.Api.Widget.open('chat')

        window.Trengo.on_chat_started = function () {
            window.Trengo.contact_data = {
                email: user.email,
                name: `${user.first_name} ${user.second_name} ${user.first_surname} ${user.second_surname}`
            }

            window.Trengo.ticket_data = {
                custom_fields: [
                    {
                        field_id: TRENGO_FIELD_ID,
                        value: user.phone_number
                    }
                ]
            }
        }

        window.Trengo = window.Trengo || {}

        window.Trengo.on_close = async () => {
            window.Trengo.ticket_data = {}
            window.Trengo.contact_data = {}

            navigate(-1)
            widget.style.display = 'none'
        }

        window.Trengo.on_chat_ended = function () {
            window.location.reload()
        }
    }

    const onBackward = () => {
        window.Trengo.Api.Widget.close()
    }

    return (
        <div className="w-full min-h-full flex flex-col px-6 py-10">
            <ChevronLeftIcon
                className="cursor-pointer w-2 mb-12"
                onClick={onBackward}
            />

            <div className="m-auto flex flex-col justify-center h-full text-center">
                <img className="mx-auto mb-8" src={ChatSVG} />
                <h1 className="text-dark-kiwi font-semibold mb-4 text-2xl">
                    Live Chat
                </h1>
                <p>
                    Bienvenido a nuestro canal de ayuda. Por favor escríbenos en
                    la ventana de la derecha como podemos ayudarte
                </p>
            </div>

            <button
                className="rounded-xl p-3 mt-10 bg-blue-kiwi text-white"
                onClick={onBackward}>
                Volver
            </button>
        </div>
    )
}
