import React, { useEffect, useContext, Fragment, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { ApprovedLoanContext } from 'context/ApprovedLoanContext'
import { TRACKING_EXPIRATION_DAYS } from 'constants'

import Persona from 'templates/Persona'
import ProgressBar from 'components/ProgressBar'
import dateDifference from 'utils/dateDifference'
import { UserContext } from 'context/UserContext'
import STATE_CONSTANTS from 'constants/states'
import { STATES_AVAILABLES } from 'constants'
import { REPAYMENT_OPTIONS } from 'constants'
import { DISBURSEMENT_METHODS } from 'constants'
import LogRocket from 'logrocket'

export default function ApprovedLoanPersona() {
    const navigate = useNavigate()

    const [showPersona, setShowPersona] = useState(false)
    const { state: approvedState } = useContext(ApprovedLoanContext)
    const { state: userState } = useContext(UserContext)

    /**
     * Se necesita esperar a que el componente padre
     * este renderizado antes de renderizar los hijos...
     * Por una validacin en Persona...
     */
    useEffect(() => {
        checkTempLoanCreatedDate()
    }, [])

    const { personaTemplate } = STATE_CONSTANTS(userState.user.state).approved

    const checkTempLoanCreatedDate = () => {
        /**
         * Se valida la fecha para mostrar el componente de persona,
         * esto porque el modal interno de persona sobrepone el del delete tracking...
         */
        if (approvedState.repaymentTerms.created_at) {
            const diffDays = dateDifference(
                approvedState.repaymentTerms.created_at
            )

            if (diffDays >= TRACKING_EXPIRATION_DAYS) {
                setShowPersona(false)
            } else {
                setShowPersona(true)
            }
        } else {
            LogRocket.captureMessage('Track Repayment Terms Error', {
                extra: {
                    approvedState
                }
            })
            return navigate('/approved-loan/amount')
        }
    }

    const onBackward = () => {
        if (
            approvedState.repaymentOption.value === REPAYMENT_OPTIONS.paperCheck
        ) {
            return navigate('/approved-loan/repayment-options')
        }

        navigate('/approved-loan/repayment-profile')
    }

    const onContinue = () => {
        if (
            userState.user.state === STATES_AVAILABLES.florida &&
            approvedState.veritec.completed === false
        ) {
            return navigate('/approved-loan/veritec')
        }

        navigate('/approved-loan/contract')
    }

    return (
        <Fragment>
            <ProgressBar progress="9/10" onBackward={onBackward} />

            {showPersona === true && (
                <Persona
                    template={personaTemplate}
                    inquiryTag={`personal_loan_${approvedState.disbursement.value}`}
                    context={ApprovedLoanContext}
                    onBackward={onBackward}
                    onContinue={onContinue}
                />
            )}
        </Fragment>
    )
}
