import React from 'react'
import { Routes, Route } from 'react-router-dom'

/**
 * Contexts
 */
import { AuthProvider } from 'context/AuthContext'
import { UserProvider } from 'context/UserContext'

/**
 * Middlewares
 */
import { AuthMiddleware } from './middlewares/AuthMiddleware'
import { UnauthMiddleware } from './middlewares/UnauthMiddleware'

import NotFound from 'views/mobile/NotFound'
import AuthRoutes from './AuthRoutes'
import UnauthRoutes from './UnauthRoutes'
import CreditLimitRoutes from './CreditLimitRoutes'
import RequestLoanRoutes from './RequestLoanRoutes'
import ApprovedLoanRoutes from './ApprovedLoanRoutes'
import ActiveLoanRoutes from './ActiveLoanRoutes'
import CreditBuilderRoutes from './CreditBuilderRoutes'
import CreditMonitoringRoutes from './CreditMonitoringRoutes'
import RouletteRoutes from './RouletteRoutes'

import CreditMonitoringEmbedRegister from 'views/mobile/CreditMonitoring/EmbedRegister'
import CreditMonitoringEmbedReport from 'views/mobile/CreditMonitoring/EmbedReport'
import Maintenance from 'views/mobile/Maintenance'

const AppRoutes = () => (
    <AuthProvider>
        <UserProvider>
            <Routes>
                <Route path="*" element={<NotFound />} />
                <Route exact path="maintenance" element={<Maintenance />} />
                <Route path="/">
                    <Route element={<UnauthMiddleware />}>{UnauthRoutes}</Route>
                    <Route element={<AuthMiddleware />}>
                        {AuthRoutes}
                        {CreditLimitRoutes}
                        {RequestLoanRoutes}
                        {ApprovedLoanRoutes}
                        {ActiveLoanRoutes}
                        {CreditBuilderRoutes}
                        {CreditMonitoringRoutes}
                        {RouletteRoutes}
                    </Route>

                    <Route
                        path="array-register"
                        element={<CreditMonitoringEmbedRegister />}></Route>
                    <Route
                        path="array-report"
                        element={<CreditMonitoringEmbedReport />}></Route>
                </Route>
            </Routes>
        </UserProvider>
    </AuthProvider>
)

export default AppRoutes
